<script setup lang="ts">
const { t } = useT();

const props = defineProps<{
	levels: number;
	active: number;
	timeEnd: string;
	isAvailable: boolean;
	isCompleted: boolean;
	isLastPrize: boolean;
}>();

const timerTitle = computed(() => {
	if (props.isLastPrize && props.isAvailable) {
		return t("The prize is available for");
	}
	return props.isAvailable ? t("Prizes expires in") : t("Your prize is waiting for you in");
});
</script>

<template>
	<div class="levels">
		<div class="levels-grid">
			<AText
				v-for="level in levels"
				:key="level"
				as="div"
				variant="tlalpan"
				:modifiers="['extrabold']"
				:class="['level', { active: level <= active }]"
			>
				<NuxtImg
					v-if="level <= active"
					src="/nuxt-img/daily-login/done.png"
					class="done"
					width="44"
					height="44"
					alt="done"
					format="avif"
					loading="lazy"
				/>
				<span v-else>{{ level }}</span>
			</AText>
			<NuxtImg
				v-if="!isCompleted"
				src="/nuxt-img/daily-login/prize.png"
				class="gift"
				width="68"
				height="68"
				alt="gift"
				format="avif"
				loading="lazy"
			/>
		</div>

		<div v-if="!isCompleted" class="level-label">
			<AText variant="topeka" :modifiers="['semibold']">
				{{ timerTitle }}
				<AText variant="topeka" :modifiers="['extrabold']" gradient="var(--goeteborg)">
					<span>{{ timeEnd }}</span>
				</AText>
			</AText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.levels {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	gap: 8px;
}

.levels-grid {
	width: 100%;
	display: flex;
	gap: 1px;
	position: relative;
}

.level-label {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.level {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	background: var(--celaya);
	color: var(--сirebon);

	&:first-of-type {
		border-radius: 16px 0 0 16px;
	}

	&:last-of-type {
		border-radius: 0 16px 16px 0;
	}

	&.active {
		background: var(--goeteborg);
	}
}

.days {
	display: flex;
	gap: 2px;
	align-items: baseline;
}

.done {
	width: 21px;
	height: 21px;
	transform: translateY(1.5px);
}

.gift {
	position: absolute;
	top: -6px;
	right: 0;
	width: 34px;
	height: 34px;
}

.grand {
	align-self: flex-end;
}
</style>
